import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoadingService } from '@shared/utils';

import { IGNORE_LOADING } from '../tokens/ignore-loading';

@Injectable({
    providedIn: 'root',
})
export class LoadingInterceptor implements HttpInterceptor {
    constructor(private loadingService: LoadingService) {}

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (req.context.get(IGNORE_LOADING)) {
            // skip loading
            return next.handle(req);
        }

        // start loading
        this.loadingService.startLoading();

        return next.handle(req).pipe(
            finalize(() => {
                // stop loading on complete
                this.loadingService.endLoading();
            }),
        );
    }
}
