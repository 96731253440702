/*
 * Usage Guide:
 *
 * 1. Add  a new object to the errors object.
 * 2. Check if there are additional properties to be added to the object.
 * 3. If you want to use these properties write a lambda function to inject these into the error message (body).

 * If no title is given the body will be shown instead and there will be no details button.
 */

import { HttpError } from '../interfaces/http-error.interface';

export const httpErrors: { [key: string]: HttpError } = {
    ENTRANCE_IN_USE: {
        title: $localize`:@@shared-http.locationInUseTitle:Location in use`,
        body: $localize`:@@shared-http.locationInUseDescription:The deleted location contains employees, please remove them before removing the location.`,
        sticky: false,
    },
    EMPLOYEE_DOES_NOT_EXIST: {
        title: $localize`:@@shared-http.title:Error`,
        body: (properties: any) => {
            const employeeId: number = properties.requestedEmployeeId;

            return $localize`:@@shared-http.employeeDoesNotExist:Employee with id ${employeeId} does not exist.`;
        },
        sticky: false,
    },
    COMPANY_LOCATION_DOES_NOT_EXIST: {
        title: $localize`:@@shared-http.title:Error`,
        body: (properties: any) => {
            const locationId: number = properties.requestedCompanyLocationId;

            return $localize`:@@shared-http.companyLocationDoesNotExist:Company location with id ${locationId} does not exist.`;
        },
        sticky: false,
    },
    ACCESS_TO_COMPANY_LOCATION_NOT_ALLOWED: {
        title: $localize`:@@shared-http.title:Error`,
        body: (properties: any) => {
            const locationId: number = properties.requestedCompanyLocationId;

            return $localize`:@@shared-http.accessToCompanyLocationWithIDNotAllowed:Access to company location with id ${locationId} not allowed.`;
        },
        sticky: false,
    },
    QUOTA_LIMIT_REACHED: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.quotaLimitReached:You reached the maximum number of Mobility Audits allocated for you.`,
        sticky: false,
    },
    UPLOADED_FILE_IS_INVALID: {
        title: $localize`:@@shared-http.title:Error`,
        body: (properties: any) => {
            return $localize`:@@shared-http.uploadedFileInvalid:Uploaded file is invalid at line ${
                properties.line + 1
            } and column ${properties.column}.`;
        },
        sticky: false,
    },
    NO_EMPLOYEE_EXISTS: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.noEmployeeExists:Company Locations needs to have employees to for a Mobility Score calculation.`,
        sticky: false,
    },
    COMPANY_LOCATION_OUT_OF_COVERED_AREA: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.companyLocationOutCoveredArea:Company location is out of covered area.`,
        sticky: false,
    },
    EMPLOYEE_OUT_OF_COVERED_AREA: {
        title: $localize`:@@shared-http.title:Error`,
        body: (properties: any) => {
            let errorString: string = $localize`:@@shared-http.followingEmployeesAreOutOfCoverArea:The following employees are out of covered area:`;
            for (const location of properties.nonCoveredEmployees) {
                errorString +=
                    $localize`:@@shared-http.outOfCoveredAreaLocation:\nlocation:` +
                    ` ${location.street} ${location.country}`;
            }

            return errorString;
        },
        sticky: true,
    },
    NOT_ENOUGH_CALCULATION_DATA: {
        title: $localize`:@@shared-http.wrongCalculationData:Error - Wrong data for calculation`,
        body: (properties: any) => {
            // UNAVAILABLE
            // DISABLED
            const unavailableModalErrors: { [mode: string]: number } = {};
            const disabledModalErrors: { [mode: string]: number } = {};
            for (const error of properties.unknownDataEmployees) {
                unavailableModalErrors[error.mode] = 0;
                disabledModalErrors[error.mode] = 0;
            }
            for (const error of properties.unknownDataEmployees) {
                if (error.reason === 'UNAVAILABLE') {
                    unavailableModalErrors[error.mode] += 1;
                } else {
                    disabledModalErrors[error.mode] += 1;
                }
            }

            let errorString: string = $localize`:@@shared-http.followingModesConfiguredWrong:The following modes are configured wrong:`;
            for (const mode in unavailableModalErrors) {
                if (unavailableModalErrors[mode] > 0) {
                    errorString += $localize`:@@shared-http.modeUnavailable:\n${unavailableModalErrors[mode]} people in ${mode} are unavailable`;
                }
            }
            for (const mode in disabledModalErrors) {
                if (disabledModalErrors[mode] > 0) {
                    errorString += $localize`:@@shared-http.modeDisabled:\n${disabledModalErrors[mode]} people in ${mode} are disabled`;
                }
            }

            return errorString;
        },
        sticky: true,
    },
    MAX_EMPLOYEES_ALLOWED_EXCEEDED: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.maxEmployeesAllowedExceeded:You reached the maximum number of employees allowed for this Mobility Audit.`,
        sticky: false,
    },
    MODAL_SPLIT_SUGGESTION_ERROR: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.modalSplitSuggestionError:Suggesting Modal Split failed.`,
        sticky: false,
    },
    DETAILED_TRIP_TYPE_NOT_ALLOWED: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.detailedTripTypeNotAllowed:Detailed trip type is not allowed when detailed trip is set!`,
        sticky: false,
    },
    INVALID_PAGE_OR_SIZE: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.invalidPageOrSizeBug:You found a bug in the application. Please contact the administrator with information how to reproduce it.\nError: INVALID_PAGE_OR_SIZE`,
        sticky: true,
    },
    NO_EMPLOYEE_HAD_WORKING_HOURS: {
        title: $localize`:@@shared-http.csvError:CSV Error`,
        body: $localize`:@@shared-http.noEmployeeHasWorkingHours:No employee has working hours defined.\nPlease specify more.`,
        sticky: false,
    },
    NOT_ALL_EMPLOYEES_HAVE_OPTIMAL_MODE: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.checkOutStatusQuoFirst:Please checkout status-quo first.`,
        sticky: false,
    },
    ESTIMATED_CARPOOLS_NOT_FOUND: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.checkOutStatusQuoFirst:Please checkout status-quo first.`,
        sticky: false,
    },
    ENTRANCE_DOES_NOT_EXIST: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.locationDoesNotExist:Location you are trying to modify does not exist.`,
        sticky: false,
    },
    HIGHER_LEVEL_REQUIRED_SERVICE: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.upgradeLicense:This service is only available for higher tier users.\nIf you want to upgrade your license contact team@mobility-audit.com`,
        sticky: true,
    },
    COMPANY_LOCATION_UPDATE_ERROR: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.companyLocationUpdateError:Company location couldn't be updated - please try again.`,
        sticky: false,
    },
    INVALID_ENTRY_OR_EXIT_TIME: {
        title: $localize`:@@shared-http.csvError:CSV Error`,
        body: (properties: any) => {
            return $localize`:@@shared-http.invalidEntryOrExitTime:Invalid entry or exit time defined in CSV file. Check employee in line or with id: ${properties.employeeNumber}.`;
        },
        sticky: true,
    },
    INVALID_CSV_DELIMITER: {
        title: $localize`:@@shared-http.csvError:CSV Error`,
        body: $localize`:@@shared-http.invalidCsvDelimiter:Invalid CSV Delimiter Detected. Please ensure that your CSV file uses a valid delimiter such as a comma (,), semicolon (;).`,
        sticky: true,
    },
    PACKAGE_UPDATE_ERROR: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.packageUpdateError:Package couldn't be updated - please try again.`,
        sticky: false,
    },
    PACKAGE_DOES_NOT_EXIST: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.packageDoesNotExist:Package you are trying to modify does not exist.`,
        sticky: false,
    },
    MOBILITY_OR_VEHICLE_STATS_CONSTRAINT_VIOLATION: {
        sticky: true,
        title: $localize`:@@shared-http.constraintViolation:Error - Constraint violation`,
        body: $localize`:@@shared-http.sumOfStatsMustBe1:The sum of all mobility and vehicle stats must be 100%.`,
    },
    EMPTY_OR_NULL_REQUIRED_PARAMETER: {
        sticky: true,
        title: $localize`:@@shared-http.title:Error`,
        body: (properties: any) => {
            return $localize`:@@shared-http.emptyOrNullRequiredParameter:The parameter ${properties.parameterName} is required.`;
        },
    },
    NO_LOCATION_PROPERTIES: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.noLocationProperties:No location properties found.`,
        ignore: true,
    },
    NO_USER_PROPERTIES: {
        title: $localize`:@@shared-http.title:Error`,
        body: $localize`:@@shared-http.noUserProperties:No user properties found.`,
        ignore: true,
    },
    FEATURE_DISABLED: {
        title: $localize`:@@shared-http.feature-not-available-error:Feature not available`,
        body: $localize`:@@shared-http.featureDisabled:The feature you are trying to use is not available at the moment.`,
    },
    ALL_EMPLOYEES_FAILED_GEOCODING: {
        title: $localize`:@@shared-http.allEmployeesFailedGeocoding:Employee upload failed`,
        body: $localize`:@@shared-http.allEmployeesFailedGeocodingDescription:Server couldn't save employees.`,
    },
    DISTANCE_CALCULATION_ERROR: {
        title: $localize`:@@shared-http.distanceCalculationError:Calculation Error`,
        body: $localize`:@@shared-http.distanceCalculationErrorDescription:Server failed when calculation distances.`,
    },
    USER_WITH_ALREADY_ASSIGNED_MOBILITY_PROPERTIES: {
        title: $localize`:@@shared-http.userAlreadyAssignedMobilityProperties:Mobility properties already present`,
    },
    LOCATION_WITH_ALREADY_ASSIGNED_MOBILITY_PROPERTIES: {
        title: $localize`:@@shared-http.locationAlreadyAssignedMobilityProperties:Mobility properties already present`,
    },
    SERVICE_IS_NOT_ALLOWED_IN_THIS_AREA: {
        title: $localize`:@@shared-http.serviceNotAllowed:Service is not allowed in this area`,
    },
    LOGIN_FAILED: {
        title: $localize`:@@shared-http.loginFailure:Login Failed`,
        body: (parameters: any) => {
            switch (parameters.reason) {
                case 'bad_credentials':
                    return $localize`:@@shared-http.badCredentials:The user name or password are incorrect`;
                case 'user_expired':
                    return $localize`:@@shared-http.userExpired:Your account has expired`;
                default: // unknown, null etc
                    return $localize`:@@shared-http.unknownError:Unknown error. Please contact support`;
            }
        },
        showRawMessage: false,
        report: false,
    },
    INVALID_EMPLOYEES_COUNT: {
        title: $localize`:@@shared-http.invalidEmployeeCount:When estimating employees, the count has to be at least 10 and lower than 10_000`,
    },
    NO_COMMUTERS_FOUND: {
        title: $localize`:@@shared-http.employeeEstimationFailed:Employee Estimation failed`,
        body: $localize`:@@shared-http.noCommutersNearTheLocation:Employees can’t be estimated for this location, no incoming commuters nearby`,
    },
    NO_MODES_ASSIGNABLE: {
        body: (parameters: any) =>
            $localize`:@@shared-http.modesCantBeAssigned:Couldn't assign modes to ${parameters.employeeIds.length} employees`,
    },
    NO_TRIPS_FOR_SELECTED_MODE: {
        title: $localize`:@@shared-http.noTripsForEmployees:No trips for employees`,
        body: (parameters: any) => {
            const idModes: { [id: string]: string } = parameters;
            let output = 'These employees have no trips for selected mode:\n';
            for (const id in idModes) {
                output +=
                    $localize`:@@shared-http.employee:Employee` +
                    `#${id} with mode ${idModes[id]}\n`;
            }

            return output;
            // $localize`:@@shared-http.noTripsForSelectedMode:No trips for selected mode for ${parameters.employeeIds.length} employees`
        },
    },
    SCENARIO_DOES_NOT_EXIST: {
        title: $localize`:@@shared-http.scenarioDoesNotExistTitle:Scenario does not exist`,
        body: (parameters: any) =>
            $localize`:@@shared-http.scenarioDoesNotExistBody:Scenario with id ${parameters.scenarioReferenceRequested} does not exist`,
    },
    ENTRANCE_NOT_FOUND: {
        title: $localize`:@@shared-http.locationNotFoundTitle:Location not found`,
        body: (parameters: any) =>
            $localize`:@@shared-http.locationNotFoundBody:Location with id ${parameters.entranceNumber} does not exist`,
    },
    'score_calculation.modes_not_assignable': {
        title: $localize`:@@shared-http.modesNotAssignableTitle:No modes could be assigned for some employees`,
        body: (parameters: any) => {
            const realIds = parameters.realIds ?? [];
            const problematicEmployeeIds = parameters.problematicEmployeeIds ?? [];

            let output = $localize`:@@shared-http.modesNotAssignableBody:Could not assign modes for ${problematicEmployeeIds.length} employees. `;

            if (
                problematicEmployeeIds.length === realIds.length &&
                realIds.length > 0 &&
                realIds.length < 10 &&
                realIds.every((id: any) => id != null)
            ) {
                output = $localize`:@@shared-http.modesNotAssignableBodyWithRealID:Could not assign modes for employees with IDs ${realIds.join(', ')}. `;
            }

            output += $localize`:@@shared-http.noRequestedTripsHelpMessage:This could be caused by a 'fixed mode' that can't be calculated or an unrealistic modal split assumption.`;

            return output;
        },
        custom: (parameters: any) => {
            return {
                problematicEmployeeIds: parameters.problematicEmployeeIds,
                companyLocationId: parameters.companyLocationId,
            };
        },
    },
    'score_calculation.no_requested_trips': {
        title: $localize`:@@shared-http.noRequestedTripsTitle:No trips could be calculated for some employees`,
        body: (parameters: any) => {
            const realIds = parameters.realIds ?? [];
            const problematicEmployeeIds = parameters.problematicEmployeeIds ?? [];

            let output = $localize`:@@shared-http.noRequestedTripsBody:Could not calculate trips for ${problematicEmployeeIds.length} employees. `;

            if (
                problematicEmployeeIds.length === realIds.length &&
                realIds.length > 0 &&
                realIds.length < 10 &&
                realIds.every((id: any) => id != null)
            ) {
                output = $localize`:@@shared-http.noRequestedTripsBodyWithRealIds:Could not calculate trips for employees with IDs ${realIds.join(', ')}. `;
            }

            output += $localize`:@@shared-http.tooManyImpossibleModes:This could be caused by too many 'impossible modes' in the employee or a problem with the employee's location.`;

            return output;
        },
        custom: (parameters: any) => {
            return {
                problematicEmployeeIds: parameters.problematicEmployeeIds,
                companyLocationId: parameters.companyLocationId,
            };
        },
    },
    R5CONNECTORS_NOT_SET_OR_POINT_NOT_COVERED: {
        title: $localize`:@@shared-http.r5ConnectorsNotSetOrPointNotCoveredTitle:Unfortunately, accessibility areas for this point can’t be calculated automatically.`,
        body: $localize`:@@shared-http.r5ConnectorsNotSetOrPointNotCoveredBody:Please contact <a href="mailto:dev@triply.at">dev@triply.at</a> to request a calculation. You can still view employee’s modes on the map and see calculated KPIs.`,
    },
    AUTHENTICATION_FAILED: {
        body: (parameters: any) => {
            switch (parameters.reason) {
                case 'JWT_EXPIRED':
                    return $localize`:@@shared-http.sessionExpired:Your session has expired. Please log in again.`;
                case 'JWT_PARSING_FAILED':
                    return $localize`:@@shared-http.jwtParsingFailed:Authentication failed. Please log in again.`;
                default: // unknown, null etc
                    return $localize`:@@shared-http.unknownError:Unknown error. Please contact support`;
            }
        },
        report: false,
    },
    TASK_NOT_FOUND: {
        ignore: true,
    },
    TASK_CANCELLED: {
        title: $localize`:@@shared-http.errorTaskCancelled:Task Cancelled`,
        body: $localize`:@@shared-http.errorTaskCancelledBody:Task was cancelled, please try again.`,
    },
    ON_GOING_CALCULATION_ERROR: {
        title: $localize`:@@shared-http.onGoingCalculationError:Another calculation is already running`,
        body: $localize`:@@shared-http.onGoingCalculationErrorBody:There is already an ongoing calculation. Please wait until it finishes.`,
    },
    UPLOAD_FIELD_PARSING_ERROR: {
        title: $localize`:@@shared-http.uploadFieldParsingError:Field couldn't be parsed`,
        body: parameters => {
            return $localize`:@@shared-http.uploadFieldParsingErrorBody:Field ${parameters.header} couldn't be parsed in row ${parameters.row} and column ${parameters.column}. Please check the file and try again.`;
        },
    },
    UPLOAD_MISSING_HEADERS: {
        title: $localize`:@@shared-http.uploadMissingHeaders:Headers missing in uploaded file`,
        body: parameters => {
            return $localize`:@@shared-http.uploadMissingHeadersBody:Missing headers in uploaded file: ${parameters.missingHeader.join(', ')}.`;
        },
    },
};
