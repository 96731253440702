import { Pipe, PipeTransform } from '@angular/core';

import { ModeString } from '@shared/map';

@Pipe({
    name: 'modeTextTranslate',
    standalone: true,
})
export class ModeTextTranslatePipe implements PipeTransform {
    transform(value: ModeString): string {
        switch (value) {
            case 'BIKE':
                return $localize`:@@bike:Bike`;
            case 'CAR_DRIVER':
            case 'CAR_DRIVER_TRAFFIC':
                return $localize`:@@carDriver:Car`;
            case 'CAR_PASSENGER':
                return $localize`:@@carPassenger:Carpooling`;
            case 'WALK':
                return $localize`:Walk@@walk:Walk`;
            case 'PUBLIC_TRANSPORT':
                return $localize`:Public Transport@@publicTransport:Public Transport`;
            case undefined:
                return $localize`:@@noMode:No Mode`;
        }
    }
}
