import { Environment } from '@shared/utils';

export const environment: Environment = {
    production: true,
    apiUrl: 'https://upscore-dev.az.triply.ai',
    sentryDsn:
        'https://271c71ff46b767cf836584343d990e29@o4504484883595264.ingest.us.sentry.io/4506830389379072',
    name: 'development',
    disableTranslations: true,
};
