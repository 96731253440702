import { DecimalPipe } from '@angular/common';
import { Injectable, LOCALE_ID, inject } from '@angular/core';

import { TripDetailsTranslations } from '@upscore-mobility-audit/map/translations/trip-details-translations';

@Injectable({
    providedIn: 'root',
})
export class FormatService {
    private readonly decimalPipe: DecimalPipe = new DecimalPipe(inject(LOCALE_ID));

    /**
     * Method to convert provided minutes to days, hours and minutes
     * @param value
     */
    public convertTime(value: number): {
        days: number;
        hours: number;
        minutes: number;
    } {
        const rawDays: number = value / 60 / 24;
        const days: number = Math.floor(rawDays);
        const rawHours: number = (rawDays - days) * 24;
        const hours: number = Math.floor(rawHours);
        const rawMinutes: number = (rawHours - hours) * 60;
        const minutes: number = Math.round(rawMinutes);

        return {
            days,
            hours,
            minutes,
        };
    }

    public formatDuration(duration: number): string {
        const { days, hours, minutes }: { days: number; hours: number; minutes: number } =
            this.convertTime(duration);

        const realHours: number = hours + days * 24;

        const durationArr: string[] = [];
        if (realHours > 0) {
            durationArr.push(
                `${this.decimalPipe.transform(realHours, '1.0-0')} ` +
                    TripDetailsTranslations.hours,
            );
        }
        if (minutes > 0 || (hours === 0 && minutes === 0)) {
            durationArr.push(
                `${this.decimalPipe.transform(minutes, '1.0-0')} ` +
                    TripDetailsTranslations.minutes,
            );
        }

        return durationArr.join(' and ');
    }

    /**
     * formats distance into meters or kilometers as needed
     * 1343 -> 1.34 kilometers
     * 987 -> 987 meters
     * @param distance
     */
    public formatDistance(distance: number): string {
        const km: number = distance / 1000;
        if (km < 1) {
            return (
                this.decimalPipe.transform(distance, '1.0-0') + ' ' + TripDetailsTranslations.meters
            );
        }

        return this.decimalPipe.transform(km, '1.0-2') + ' ' + TripDetailsTranslations.kilometers;
    }
}
