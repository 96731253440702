import { NumberUnit } from '../enums';
import { CurrencyOptions } from '../interfaces';

export const getMeasure = (
    value: number,
    unit: NumberUnit,
    currencyOptions?: CurrencyOptions,
): string => {
    value = Math.abs(value);
    switch (unit) {
        case NumberUnit.DISTANCE:
            return value >= 1 || value === 0 ? 'km' : 'm';
        case NumberUnit.WEIGHT:
            return value >= 1 || value === 0 ? 't' : 'kg';
        case NumberUnit.SMALL_WEIGHT:
            return value >= 1 || value === 0 ? 'kg' : 'g';
        case NumberUnit.MONEY:
            if (currencyOptions) {
                if (!currencyOptions.smallUnit) {
                    return currencyOptions.largeUnit;
                }

                return value >= 1 || value === 0
                    ? currencyOptions.largeUnit
                    : currencyOptions.smallUnit ?? '';
            } else {
                return value >= 1 || value === 0 ? '€' : 'cent';
            }
        case NumberUnit.PERCENTAGE:
            return '%';
    }

    return '';
};

export const getNumberAbbreviation = (value: number): string => {
    value = Math.abs(value);
    if (value >= 1000 && value < 1000000) {
        return 'k';
    } else if (value >= 1000000) {
        return 'm';
    } else {
        return '';
    }
};

/**
 * Method to get the smaller measure step for a provided unit
 * @param unit
 */
export const getSmallerMeasureStep = (unit: NumberUnit): number => {
    switch (unit) {
        case NumberUnit.DISTANCE:
        case NumberUnit.WEIGHT:
        case NumberUnit.SMALL_WEIGHT:
            return 1000;
        case NumberUnit.MONEY:
            return 100;
    }

    return 1;
};

/**
 * Method to update values which is necessary when:
 * - switching to smaller unit (e.g.:t -> kg)
 * - using abbreviation (e.g. 1000 -> 1k)
 * @param value
 * @param unit
 * @param round
 * @param allowSmaller
 */
export const getSwitchedValue = (
    value: number,
    unit: NumberUnit,
    round = false,
    allowSmaller = true,
): number => {
    if (round) {
        value = Math.round(value);
    }
    const compareValue = Math.abs(value);
    if (
        compareValue < 1 &&
        allowSmaller &&
        (unit === NumberUnit.DISTANCE ||
            unit === NumberUnit.WEIGHT ||
            unit === NumberUnit.MONEY ||
            unit === NumberUnit.SMALL_WEIGHT)
    ) {
        return value * getSmallerMeasureStep(unit);
    } else if (compareValue >= 1000 && compareValue < 1000000) {
        return value / 1000;
    } else if (compareValue >= 1000000) {
        return value / 1000000;
    } else {
        return value;
    }
};

export const convertTime = (
    value: number,
): {
    days: number;
    hours: number;
    minutes: number;
} => {
    const rawDays: number = value / 60 / 24;
    const days: number = Math.floor(rawDays);
    const rawHours: number = (rawDays - days) * 24;
    const hours: number = Math.floor(rawHours);
    const rawMinutes: number = (rawHours - hours) * 60;
    const minutes: number = Math.round(rawMinutes);

    return {
        days,
        hours,
        minutes,
    };
};
