import { inject } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';

import { AuthGuard } from '@auth/data-access-auth';

import { FeatureShellComponent } from './feature-shell/feature-shell.component';

export const appRoutes: Route[] = [
    {
        path: 'auth',
        loadChildren: () => import('@auth/feature-auth').then(m => m.AuthModule),
        canMatch: [() => inject(AuthGuard).isLoggedOut()],
        data: {
            redirect: '/dashboard',
        },
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
    },
    {
        path: '',
        component: FeatureShellComponent,
        canMatch: [
            (route: Route, segments: UrlSegment[]) => inject(AuthGuard).isLoggedIn(route, segments),
        ],
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('@upscore-admin/dashboard/feature-dashboard').then(
                        m => m.featureDashboardRoutes,
                    ),
            },
            {
                path: 'feature-flags',
                loadChildren: () =>
                    import('@upscore-admin/feature-flags/feature-feature-flags').then(
                        m => m.featureFlagsRoutes,
                    ),
            },
            {
                path: 'users',
                loadChildren: () =>
                    import('@upscore-admin/users/feature-users').then(m => m.usersRoutes),
            },
            {
                path: 'traffic-zones',
                loadChildren: () =>
                    import('@upscore-admin/traffic-zones/feature-traffic-zones').then(
                        m => m.trafficZonesRoutes,
                    ),
            },
            {
                path: 'public-transport-tickets',
                loadChildren: () =>
                    import(
                        '@upscore-admin/public-transport-tickets/feature-public-transport-tickets'
                    ).then(m => m.publicTransportPricingZonesRoutes),
            },
            {
                path: 'tasks',
                loadChildren: () =>
                    import('@upscore-admin/tasks/feature-tasks').then(m => m.taskRoutes),
            },
        ],
    },
    // {
    //     path: '**',
    //     component: NotFoundComponent,
    // },
];
