import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { ModeColorPipe } from '@upscore-mobility-audit/shared-ui/pipes/mode-color/mode-color.pipe';

@Component({
    selector: 'mode-icon',
    standalone: true,
    imports: [CommonModule, MatIcon, ModeColorPipe],
    templateUrl: './mode-icon.component.html',
    styleUrl: './mode-icon.component.scss',
})
export class ModeIconComponent {
    mode = input.required<
        'PUBLIC_TRANSPORT' | 'CAR_DRIVER' | 'CAR_PASSENGER' | 'BIKE' | 'WALK' | 'UNKNOWN'
    >();
}
