import { Pipe, PipeTransform } from '@angular/core';

import { ModeString } from '@shared/map';
import { ThemeService } from '@shared/utils';

@Pipe({
    name: 'modeColor',
    pure: true,
    standalone: true,
})
export class ModeColorPipe implements PipeTransform {
    constructor(private themeService: ThemeService) {}
    transform(
        mode: Exclude<ModeString | 'UNKNOWN', undefined>,
        returnType: 'variable' | 'hexColor' = 'variable',
    ): string {
        const variable = this.themeService.getModeColorCSSVariable(mode);
        if (returnType == 'variable') {
            return variable;
        }

        return this.themeService.getHexFromCSSVariable(variable);
    }
}
