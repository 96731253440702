import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';

import { LoadingService } from '@shared/utils';
import { UiLibraryModule } from '@ui-library/ui-library.module';
import { UpscoreAdminSharedModule } from '@upscore-admin/shared';

@Component({
    standalone: true,
    imports: [RouterModule, UiLibraryModule, CommonModule, UpscoreAdminSharedModule],
    selector: 'upscore-admin-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    public loading = toSignal(this.loadingService.loading(), {
        requireSync: true,
    });
    public loadingText = toSignal(this.loadingService.loadingText, {
        requireSync: true,
    });

    constructor(private loadingService: LoadingService) {}
}
