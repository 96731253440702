<div class="flex justify-between items-center w-full h-xxxl p-xxl bg-grey-ultralight">
    <img
        class="h-xxxl"
        alt="Mobility Score Logo"
        src="assets/images/upscore-brand/mobility-score-logo-transparent.png" />

    <div class="flex gap-xl">
        <a [routerLinkActive]="'border-b-2'" [routerLink]="['/dashboard']" routerLinkActive="active"
            >Home</a
        >
        <a [routerLink]="['/']" routerLinkActive="active" (click)="logout()">Logout</a>
    </div>
</div>
